import React from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Layout from "../../components/layout"

// import DynamicPageTemplate from "./dynamic-page"
import {
  ArticleBody,
  Heading2,
  Paragraph,
  MinorColumn,
  MajorColumn,
  FlexWrapMobile
} from "../../components/content-elements"
import { graphql } from "gatsby"
import SEO from "../../components/seo"


const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const formName = "contactUs"

const ContactPage = ({ data, location }) => {

  const { page } = data

  return (

    <Layout location={location}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.frontmatter.title}
      />
      <FlexWrapMobile>
        <MinorColumn>
          <Heading2>
            {page.frontmatter.heading}
          </Heading2>
          <ArticleBody>
            <Paragraph>
              {page.frontmatter.subheading}
            </Paragraph>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Management</dt>
                <dd>
                  <Paragraph>John is represented by <a className="text-blue-500"
                                                       href={"http://www.connaughtartists.com/index.php/john-gibbons/"}>Connaught
                    Artists</a></Paragraph>
                </dd>
              </div>

              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">

                  <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <span className="ml-3">
                 Tel: +44 (0)207 426 5503 Mob: +44 (0)7816 874245.
              </span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">

                  <svg className="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <span className="ml-3">
                patrick@connaughtartists.com
              </span>
                </dd>
              </div>
            </dl>
          </ArticleBody>
        </MinorColumn>

        <MajorColumn>
          <Formik
            initialValues={{
              full_name: "",
              email: "",
              message: ""
            }}
            onSubmit={
              (values, actions) => {
                //alert(JSON.stringify(values, null, 2))
                fetch("/", {
                  method: "POST",
                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                  body: encode({ "form-name": formName, ...values })
                })
                  .then(() => {
                    alert("Success")
                    actions.resetForm()
                  })
                  .catch(() => {
                    alert("Error")
                  })
                  .finally(() => actions.setSubmitting(false)) //}).then(() => navigate("/thank-you/")).catch(error => alert(error))
              }
            }
            validate={values => {
              //alert(JSON.stringify(values, null, 2))
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              const errors = {}
              if (!values.full_name) {
                errors.full_name = "Name Required"
              }
              if (!values.email || !emailRegex.test(values.email)) {
                errors.email = "Valid Email Required"
              }
              if (!values.message) {
                errors.message = "Message Required"
              }
              return errors
            }}
          >
            {() => (
              <Form name={formName} action="#" method="POST" data-netlify="true" netlify-honeypot="bot-field"
                    className="grid grid-cols-1 gap-y-6">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value={formName} />
                <div>
                  <label htmlFor="full_name" className="sr-only">Full name</label>
                  <Field type="text" name="full_name" id="full_name" autoComplete="name"
                         className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                         placeholder="Full name" />
                  <div className="text-sm font-medium text-red-800">
                    <ErrorMessage name="full_name" /></div>
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">Email</label>
                  <Field id="email" name="email" type="email" autoComplete="email"
                         className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                         placeholder="Email" />
                  <div className="text-sm font-medium text-red-800"><ErrorMessage name="email" /></div>
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">Phone</label>
                  <Field type="text" name="phone" id="phone" autoComplete="tel"
                         className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                         placeholder="Phone" />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">Message</label>
                  <Field component={"textarea"} id="message" name="message" rows="4"
                         className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                         placeholder="Message" />
                  <div className="text-sm font-medium text-red-800"><ErrorMessage name="message" /></div>
                </div>
                <div>
                  <button type="submit"
                          className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Send
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </MajorColumn>
      </FlexWrapMobile>


    </Layout>
  )
}

export const pageQuery = graphql`
    query contactById($id: String!) {
        page: markdownRemark(id: {eq: $id}) {
            ...GeneralDynamciPageFields
        }
        marketing: markdownRemark(id: {eq: $id}) {
            ...SEOFields
            ...MarketingFields
        }
    }
`

export default ContactPage


